// DO NOT EDIT THIS FILE !!!! GENERATED DURING BUILD TYPE : Ansible
export const DOL_VERSION='[master]6.1-10754(8bcbbba496)-C22/10/2024-19:45:18-B22/10/2024-20:08:13' ;
export const DOL_VERSION_JSON = {
  dolVersion: "[master]6.1-10754(8bcbbba496)-C22/10/2024-19:45:18-B22/10/2024-20:08:13",
  branch: "master",
  latestTag: "6.1",
  revCount: "10754",
  shortHash: "8bcbbba496",
  longHash: "8bcbbba496616b058619542248057ec91f4ddbf4",
  dateCommit: "22/10/2024-19:45:18",
  dateBuild: "22/10/2024-20:08:13",
  buildType: "Ansible",
  } ;
